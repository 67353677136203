import * as React from "react"
import PageLayout from "../../components/pageLayout";



const ImportIndexPage = () => {

    return (<PageLayout></PageLayout>)
}

export default ImportIndexPage;